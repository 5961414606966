import React from 'react'
import serviceInfoStyles from './service-info.module.css'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

class ServiceInfo extends React.Component {
  constructor(props) {
    super(props)

    this.containerRef = React.createRef()
  }

  componentDidMount() {
    this.containerRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }

  render() {
    return (
      <div className={serviceInfoStyles.container}>
        <div
          ref={this.containerRef}
          className={serviceInfoStyles.scrollToElement}
        />
        <article className={serviceInfoStyles.content}>
          <div>
            <h2>{this.props.service.title}</h2>
            <p dangerouslySetInnerHTML={{ __html: this.props.service.html }} />
          </div>
          <Img
            className={serviceInfoStyles.image}
            sizes={this.props.service.image.childImageSharp.sizes}
            alt={this.props.service.title}
            style={{ height: '350px' }}
          />
        </article>
      </div>
    )
  }
}

ServiceInfo.propTypes = {
  service: PropTypes.object,
}

export default ServiceInfo
