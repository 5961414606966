import React from 'react'
import Layout from '../components/layout'
import Service from '../components/service'
import ServiceInfo from '../components/service-info'

import servicesStyles from './services.module.css'
import '../utils/fontawesome-setup'

import { graphql, StaticQuery } from 'gatsby'

class ServicesPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { activeService: {} }
  }

  onServiceClick = service => {
    this.setState(() => ({ activeService: service }))
  }

  gridItems(data) {
    // TODO probably cleaner as a forEach
    return data.allMarkdownRemark.edges.reduce((gridItems, { node }) => {
      const service = node.frontmatter
      service.html = node.html

      gridItems.push(
        <Service
          key={service.title}
          icon={service.icon}
          title={service.title}
          onPress={() => this.onServiceClick(service)}
        />
      )
      if (
        this.state.activeService &&
        service.title === this.state.activeService.title
      ) {
        gridItems.push(
          <div key={`${service.title}-info`} className={servicesStyles.info}>
            <ServiceInfo key={service} service={service} />
          </div>
        )
      }
      return gridItems
    }, [])
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            allMarkdownRemark(
              filter: { fields: { collection: { eq: "services" } } }
            ) {
              edges {
                node {
                  html
                  frontmatter {
                    title
                    icon
                    image {
                      childImageSharp {
                        sizes(maxWidth: 630) {
                          ...GatsbyImageSharpSizes
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <Layout>
            <h1 style={{ paddingLeft: '20px' }}>Services</h1>
            <div className={servicesStyles.container}>
              {this.gridItems(data)}
            </div>
          </Layout>
        )}
      />
    )
  }
}

export default ServicesPage
