import React from 'react'
import serviceStyles from './service.module.css'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Service = props => (
  <button
    className={serviceStyles.button}
    onClick={props.onPress}
    title={props.title}
  >
    <div className={serviceStyles.circle}>
      <FontAwesomeIcon icon={props.icon} size={'2x'} />
    </div>
    <h4 className={serviceStyles.title}>{props.title}</h4>
  </button>
)

Service.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  onPress: PropTypes.func,
}

export default Service
